@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --ubcani-yellow: #fdcc1c;
  --ubcani-blue: #0f125b;
  --ubcani-green: #2ecdbf;
  --ubcani-light-blue: #0affe0;
  --ubcani-aqua: #9afff6;
  --ubcani-light-grey: #f9f9f9;
  --ubcani-pink: #fd0f92;

  color: white;
}

p {
  font-size: calc(7px + 2vmin);
  color: white;

  @media (width >= 64rem) {  
    font-size: calc(5px + 2vmin);
  }
}

h1 {
  font-family: "Dela Gothic One", sans-serif;
  font-size: calc(20px + 2vmin);
  font-weight: 400;
  font-style: normal;
  text-shadow: -1px 1px 10px rgba(0, 0, 0, 0.25)
}

h2 {
  font-family: "League Gothic", sans-serif;
  font-size: calc(15px + 2vmin);
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "wdth" 100;
}

h3 {
  font-family: "Dela Gothic One", sans-serif;
  font-size: calc(12px + 2vmin);
  font-weight: 200;
  font-style: normal;
}

li {
  font-family: 'Figtree', sans-serif; 
  font-weight: 400;
  font-style: normal;
}

button {
  font-family: 'Figtree', sans-serif; 
  font-weight: 400;
  font-style: normal;
}

input {
  font-family: 'Figtree', sans-serif; 
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Figtree', sans-serif; 
    font-weight: 400;
    font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}




code {
  font-family: ''source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace'';
}

.graphics-button {
  @apply bg-blue-100 text-ubcani-blue p-4 text-[4vw] lg:text-2xl lg:w-fit cursor-pointer font-bold text-center rounded-[25px] border-none text-pretty;
}