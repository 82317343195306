/* @import "tailwindcss"; */

.App {
  overflow-x: hidden;
  max-height: 100vh;
  background-color: #0f125b;
}

.App-link {
  color: #61dafb;
}

.Intro {
  background-color: #0f125b;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 250px;
  padding-top: 100px;
}

.Ellipse {
  float: left;
  padding-right: 110px;
  width: 600px;
  height: 600px;
}


.Intro-text {
  float: right;
  text-align: left;
  padding-left: 100px;
}

.WhoAreWe h1 {
  color: #fe1b88;
}

.WeeklyEvents h1 {
  color: #fdcc1c;
}

.Community h1 {
  color: #9afff6;
}

.hanami {
  position: relative;
}

/* .hanamiTextArea {
  position: absolute;
  bottom: 10px;
  left: 1200px;
} */

.membership h1 {
  text-align: center;
  color: white;
}

.membership bd{
  font-weight: 800;
}

.membership img {
  float: right;
}

.carousel .thumb.selected {
  border-color: white;
}

.carousel .thumb {
  border-color: grey;
}