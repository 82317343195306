.artist-collective {
  padding: 100px;
  background-color: var(--ubcani-blue);
}

.artist-collective-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.artist-collective-title {
  position: relative;
  width: 870px;
  /* font-size: 85px; */
  line-height: 100px;
  margin-bottom: 30px;
  color: white;
}

.artist-collective-title img {
  position: absolute;
  left: 330px;
  top: -15px;
  width: 70px;
  height: 114px;
}

.collective {
  position: relative;
  display: inline-block;
}

.collective::after {
  content: "";
  position: absolute;
  /* left: 0;
  bottom: 40; */
  width: 100%;
  height: 5px;
  background-color: var(--ubcani-yellow);
}

.artist-collective-header p {
  font-size: 1.2rem;
  color: white;
  margin: 10px 0;
}

.content {
  display: flex;
  position: relative;
}

.description {
  /* font-size: 1.2rem; */
  color: white;
}

.apply-button {
  position: absolute;
  background-color: var(--ubcani-yellow);
  color: var(--ubcani-blue);
  right: 100px;
  bottom: 30px;
  border: none;
  border-radius: 25px;
  padding: 15px 30px;
  cursor: pointer;
  /* font-size: 1rem; */
  margin-left: 20px;
  font-weight: bold;
}

.artist-grid {
  display: grid;
  grid-template-columns: repeat(3, 2fr);
  gap: 10px;
  margin-top: 4px;

  @media screen(lg) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 20px;
    margin-top: 40px;
  }
}

.artist-icon {
  display: flex;
  flex-direction: column;
  color: var(--ubcani-light-grey);
  align-items: center;
  text-align: center;
  justify-content: center;
  /* font-size: 1.2rem; */
  font-weight: bold;
  padding: 10px;
}

.artist-icon img {
  width: 75px;
  height: 75px;
  object-fit: cover;
  border-radius: 50px;
  border: 2px solid var(--ubcani-light-grey);
  box-sizing: border-box;

  @media screen(lg) {
    width: 100px;
    height: 100px;
  }
}

.artist-icon img:hover {
  border: 2px solid var(--ubcani-yellow);
}

.artist-icon:hover {
  color: var(--ubcani-yellow);
}
