.eventGalleryGrid {
    @apply flex flex-col xl:grid grid-cols-3 px-4 lg:px-16 gap-x-10 items-stretch pb-10 lg:pb-20;
}

.schoolYearElement {
    @apply flex flex-row lg:h-48
}

.schoolYearElement img {
    @apply object-contain h-1/2
}

.schoolYearElement h2 {
    @apply text-[20vw] lg:text-8xl content-center
}

.eventGalleryElement {
    @apply flex flex-col
}

.eventGalleryChild1 {
    @apply border-solid border-8 mx-auto rounded-3xl hover:border-ubcani-yellow overflow-hidden w-full h-[200px]
}

.eventGalleryElement h3 {
    @apply text-right pb-10 text-[5vw] lg:text-2xl
}

.eventGalleryElement img {
    @apply object-cover w-full h-full
}