/* .Footer {
  background-color: #c9ecff;
  color: #0F125B;
}

.Footer-section {
  height: 384px;
  display: flex;
  flex-direction: row;
  text-align: left;
  margin: 0;
  padding: 20px;
  font-size: 1.5rem;
  position: relative;
} */

/* .tano {
  position: absolute;
  height: 100%;
  bottom: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
}

.shiani {
  position: absolute;
  height: 100%;
  object-fit: cover;
  bottom: 0;
  right: 0;
  left: auto;
  margin-left: auto;
  margin-right: auto;

} */

/* .Newsletter-form {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  position: relative;
  width: 100%;
  max-width: 34rem;
}

.Newsletter-input {
  padding: 20px 20px;
  border-radius: 5px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.5rem;
  padding-right: 100px;
}

.Subscribe-btn {
  position: absolute;
  right: 20px;
  padding: 25px 10px;
  background-color: #2ECDBF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  height: 50%;
  align-self: center;
  align-items: center;
  font-size: 1.5rem;
  display: flex;
}

.Footer-link {
  left: 0;
  right: 0;
} */


.Footer-section p {
  @apply text-background-blue text-[5vw] md:text-[24px]
}