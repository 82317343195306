.Navbar {
  background-color: #0f125b;

}


.Navbar li {
  cursor: pointer;
  white-space: nowrap;
  /* Prevents text from breaking into multiple lines */

  @media screen(lg) {
    width: auto;
  }
}

.Navbar-link {
  text-decoration: none;
  border-radius: 1px;
  border-bottom: 0;
  padding: 10px 20px;
  display: inline;
  width: auto;
}

.Dropdown-container {
  grid-column: 1 / span 2;
  grid-row: 2;
  justify-self: center;

  width: 100%;
  background-color: #0f125b;

  @media screen(lg) {
    display: block;
    position: relative;
    top: auto;
    width: auto;
    overflow-x: visible;
    overflow-y: visible;
    height: auto;

    justify-self: center;
  }
}

.Dropdown-container>* {
  text-align: center;
}

.responsive-dropdown-btn {
  grid-column: 2;
  justify-self: end;
}

.dropdown>.Navbar-link {
  flex-grow: 1;
}

.about-btn {
  cursor: pointer;

  @media screen(lg) {
    padding: 10px 20px;
  }
}

.about-btn:hover {
  color: var(--ubcani-yellow);
  cursor: pointer;
  padding: 10px 20px;
}

.events-btn {
  cursor: pointer;

  @media screen(lg) {
    padding: 10px 20px;
  }
}

.events-btn:hover {
  color: var(--ubcani-light-blue);
  cursor: pointer;
  padding: 10px 20px;
}

.membership-btn {
  cursor: pointer;

  @media screen(lg) {
    padding: 10px 20px;
  }
}

.membership-btn:hover {
  color: var(--ubcani-pink);
  cursor: pointer;
  padding: 10px 20px;
}

.sponsor-btn {
  cursor: pointer;

  @media screen(lg) {
    padding: 10px 20px;
  }
}

.sponsor-btn:hover {
  color: var(--ubcani-yellow);
  cursor: pointer;
  padding: 10px 20px;
}

.dropdown-content {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  color: #0f125b;
  width: 200%;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  border-radius: 5px;
  text-align: left;
}

.dropdown-content li {
  padding: 8px 16px;
}

.dropdown-content a {
  color: #0f125b;
  text-decoration: none;
}

.dropdown:hover .dropdown-content {
  visibility: visible;
  opacity: 1;
}

#navbar-large-screen {
  @apply hidden lg:block
}

#navbar-small-screen {
  @apply lg:hidden
}

.dropdown-li-mobile {
  @apply text-left pl-8 py-2 text-[4vw]
}

.join-us-btn {
  color: #0f125b;
  text-decoration: none;
  padding: 5px 0px 5px 20px;
  display: block;
  width: 100vw;
  font-weight: bold;

  @media screen(lg) {

    padding: 10px 20px;
    border-radius: 20px;
    text-decoration: none;
    font-weight: bold;
    width: auto;
  }
}

.join-us-btn:hover {
  background-color: var(--ubcani-light-blue);
}