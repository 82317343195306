
.App {
  overflow: auto;
  max-height: 100vh;
  background-color: #0f125b;
}

/* .App-header {
  background-color: #0f125b;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

.Intro {
  background-color: #0f125b;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-bottom: 250px;
  padding-top: 100px;
}

.Ellipse {
  float: left;
  padding-right: 110px;
  width: 600px;
  height: 600px;
}


.Intro-text {
  float: right;
  text-align: left;
  padding-left: 100px;
}

.WhoAreWe h1 {
  color: #fe1b88;
}

.WeeklyEvents h1 {
  color: #fdcc1c;
}

.Community h1 {
  color: #9afff6;
}

.hanami {
  position: relative;
}

title {
  font-size:150px

}