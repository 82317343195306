
.App {
  overflow: auto;
  max-height: 100vh;
  background-color: #0f125b;
}

/* .App-header {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
} */

.App-link {
  color: #61dafb;
}

.Intro-text {
  float: right;
  text-align: left;
  padding-left: 100px;
}

.About {
  background-color: #0f125b;
}

/* .About h1{
  font-size: 80px;
  padding-left: 80px;
}

.About h2{
  font-size: 100px;
} */

